
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/views/modals/Card.vue";
import KTNewCardModal from "@/components/modals/forms/NewCardModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "new-card",
  components: {
    KTModalCard,
    KTNewCardModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("New Card", ["Modals", "Forms"]);
    });
  },
});
